export enum TrialLicenseState {
  Available = 'Available',
  NotAvailable = 'NotAvailable',
  Active = 'Active',
  Expired = 'Expired',
}

export type Subject = {
  id: string;
  name: string;
  shortName: string;
};

export type Grade = {
  name: string;
  slug: string;
  year: string;
};

export type EducationalStage = {
  year: string;
  name: string;
  slug: string;
};

export enum LicenseType {
  Normal = 'normal',
  Trial = 'trial',
  Preview = 'preview',
  PreviewTrial = 'preview-trial',
}

export type License = {
  id: string;
  expires: string;
  type: LicenseType;
  removed: boolean;
  status: string;
};

export type EducationalMaterial = {
  id: string;
  label: string;
  slug: string;
  subjects: Subject[];
  grade: Grade;
  materialLink: string;
};

export type TagInfo = {
  tag: string;
  subjecName: string;
};

export type Product = {
  id: string;
  title: string;
  slug: string;
  subjects: Subject[];
  thumbnailSrc: string;
  grade: Grade;
  educationalStage: EducationalStage;
  type: 'gdl' | 'gdl_bundle' | 'komplement' | 'fokus';
  license: License;
  availableLicenses: boolean;
  availableForTrial: boolean;
  educationalMaterials: EducationalMaterial[];
  tags: TagInfo[];
  materialLink: string;
  testLicenseWithRegularLicenseAvailable: boolean;
  isGy25: boolean;
  isKomvux25: boolean;
  educationalMaterialId?: string;
  additionalCardBadge?: string;
};

export enum TabType {
  MyEducationalMaterial = 'my educational material',
  Activate = 'activate',
  Trial = 'trial',
}
