import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { RadioGroup, RadioGroupOption, ButtonBig, Spacing, InfoBox } from '@gleerups/syntax4';
import { useAuth } from '@gleerups/auth';

import styled from 'styled-components';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import { LicenseInfo, ProductInfo } from '../types';
import { defaultOption, licenseOptions } from '../helpers';
import ConfirmationDialog from './ConfirmationDialog';
import api from '../../api/api';
import { useTypedSelector } from '../../store/rootReducer';
import {
  getProduct,
  getProductTrialLicenseState,
  clearProducts,
  loadLicensedProducts,
  loadAvailableAndLicensedProducts,
  loadTrialProducts,
  getTrialProducts,
} from '../../Products/productsReducer';
import { TrialLicenseState } from '../../Products/types';
import { RoutePaths } from '../../routePaths';
import { reportError } from '@gleerups/error-reporter';

type ActivationContentProps = {
  regularLicences: LicenseInfo[];
  productInfo: ProductInfo;
  setActivatedLicenseId: (licenseId: string | null) => void;
  setActivationOngoing: (isOngoing: boolean) => void;
  activationFailureType: 'none' | 'unknown' | 'multipleTrials';
  setActivationFailureType: (type: 'none' | 'unknown' | 'multipleTrials') => void;
  educationalMaterialIdOrProductId: string;
};

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${Spacing.XXL};
`;

const StyledInfoBox = styled(InfoBox)`
  margin-top: ${Spacing.M};
  margin-bottom: -${Spacing.M};
`;

const getNumberOfAvailableLicenses = (
  chosenLicense: RadioGroupOption | null,
  regularLicenses: LicenseInfo[]
) => {
  if (chosenLicense) {
    const matchingLicenses = regularLicenses.filter(
      (license: LicenseInfo) => license.id === chosenLicense.value
    );
    if (matchingLicenses && matchingLicenses.length) {
      return matchingLicenses[0].surplus;
    }
  }
  return -1;
};

const ActivationContent = ({
  regularLicences,
  productInfo,
  setActivatedLicenseId,
  setActivationOngoing,
  activationFailureType,
  setActivationFailureType,
  educationalMaterialIdOrProductId,
}: ActivationContentProps) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { user } = useAuth();

  const { title: productTitle } = productInfo;

  const productTrialLicenseState = useTypedSelector(
    getProductTrialLicenseState(educationalMaterialIdOrProductId)
  );
  const product = useTypedSelector(getProduct(educationalMaterialIdOrProductId));
  const trialProducts = useTypedSelector(getTrialProducts);
  const [availableLicenses, setAvailableLicenses] = useState<RadioGroupOption[]>([]);
  const [chosenLicense, setChosenLicense] = useState<RadioGroupOption | null>(null);

  const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false);

  const selectOption = (option: RadioGroupOption) => {
    setChosenLicense(option);
  };

  const activateButtonClick = () => {
    setActivationFailureType('none');
    if (chosenLicense?.value === 'trial') {
      activateTrialLicense();
    } else {
      setShowConfirmationDialog(true);
    }
  };

  const reloadProducts = async () => {
    if (activationFailureType === 'none') {
      dispatch(clearProducts());
      if (pathname.includes(RoutePaths.ACTIVATE_LICENSE)) {
        dispatch(loadAvailableAndLicensedProducts());
      } else if (pathname.includes(RoutePaths.TRIAL)) {
        dispatch(loadTrialProducts(user.isAuthenticated));
      } else {
        dispatch(loadLicensedProducts(true));
      }
    }
  };

  const activateTrialLicense = async () => {
    setActivationOngoing(true);
    try {
      await api.activateTrialLicense(educationalMaterialIdOrProductId);
      setActivatedLicenseId('trial');
    } catch (err) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (err.response) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const responseBody = await err.response.json();
        if (responseBody.type === 'AlreadyTriedProductException') {
          setActivationFailureType('multipleTrials');
        } else {
          setActivationFailureType('unknown');
        }
      } else {
        setActivationFailureType('unknown');
        console.error(err);
      }
    }
    setActivationOngoing(false);
    reloadProducts();
  };

  const activateLicense = async () => {
    setActivationOngoing(true);
    try {
      const licenseId = chosenLicense && chosenLicense.value;
      if (licenseId) {
        await api.activateLicense(licenseId);
        setActivatedLicenseId(licenseId);
      }
    } catch (err) {
      console.error(err);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      reportError(err, {});
      setActivationFailureType('unknown');
    }
    setShowConfirmationDialog(false);
    setActivationOngoing(false);
    reloadProducts();
  };

  useEffect(() => {
    // make sure that trial product has been loaded when this dialog
    // has been opened directly from a /prova/1234 link see CONX-2420
    // notice this can happen both when user is logged in and not logged in see CONX-2452
    if (trialProducts.length === 0 && !user.isAuthenticated) {
      dispatch(loadTrialProducts(false));
    }

    setAvailableLicenses(
      licenseOptions(regularLicences, productTrialLicenseState, product?.license)
    );
  }, [regularLicences, trialProducts]);

  useEffect(() => {
    const openedFromTrialPage = pathname.includes(RoutePaths.TRIAL);
    const defaultLicense = defaultOption(
      availableLicenses,
      openedFromTrialPage,
      productTrialLicenseState !== TrialLicenseState.NotAvailable
    );
    setChosenLicense(defaultLicense);
  }, [availableLicenses]);

  return (
    <>
      {chosenLicense && user.isAuthenticated && (
        <RadioGroup
          options={availableLicenses}
          onSelect={selectOption}
          aria-label="Välj licens att aktivera"
          value={chosenLicense.value}
        />
      )}

      {activationFailureType === 'unknown' && (
        <StyledInfoBox
          title="Oj, något gick fel...."
          color="light"
          icon={<ErrorOutlineOutlinedIcon />}
        >
          Prova igen och om problemet kvarstår, vänligen kontakta{' '}
          <a href="/support" target="_blank">
            Gleerups support
          </a>
          .
        </StyledInfoBox>
      )}

      {activationFailureType === 'multipleTrials' && (
        <StyledInfoBox
          title="Oj, något gick fel...."
          color="light"
          icon={<ErrorOutlineOutlinedIcon />}
        >
          Du har redan provat detta läromedel. Vill du prova det igen? Vänligen kontakta{' '}
          <a href="/support" target="_blank">
            Gleerups support
          </a>
          .
        </StyledInfoBox>
      )}

      <ButtonWrapper>
        {user.isAuthenticated ? (
          <ButtonBig color="blue" onClick={activateButtonClick}>
            Aktivera vald licens
          </ButtonBig>
        ) : (
          <ButtonBig
            color="blue"
            href={`/login/prova/?trialId=${educationalMaterialIdOrProductId}`}
          >
            Prova på
          </ButtonBig>
        )}
      </ButtonWrapper>

      {showConfirmationDialog && (
        <ConfirmationDialog
          onClose={() => setShowConfirmationDialog(false)}
          numberOfAvailableLicenses={getNumberOfAvailableLicenses(chosenLicense, regularLicences)}
          activateLicense={activateLicense}
          productTitle={productTitle}
        />
      )}
    </>
  );
};

export default ActivationContent;
